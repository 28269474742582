import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import './styles/blog.css'
import CloudinaryImage from '../components/CloudinaryImage'
import Seo from '../components/Seo'

const Blog = ({ data }) => {
  return (
    <Layout>
      <Seo title="Blog" />

      <div className="layout-narrow">
        <div className="blog__wrapper">
          <div className="blog__top">
            <h1 className="blog__title">{data.nodeArticle.title}</h1>
            <h2 className="blog__subtitle">
              {data.nodeArticle.field_subheading}
            </h2>
            <div className="blog__date">{data.created}</div>
          </div>
          <CloudinaryImage
            className="blog__image"
            height="505"
            width="760"
            src={data.nodeArticle.relationships.field_image[0].uri.url}
          />
          <div
            className="blog__body"
            dangerouslySetInnerHTML={{ __html: data.nodeArticle.body.value }}
          ></div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($nid: Int) {
    nodeArticle(drupal_internal__nid: { eq: $nid }) {
      id
      title
      created(formatString: "MMM DD YYYY")
      body {
        value
      }
      field_subheading
      relationships {
        field_image {
          uri {
            url
          }
        }
      }
    }
  }
`

export default Blog
